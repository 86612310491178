<template>
  <div>
    <div class="row branding-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.BRANDING')">
          <template v-slot:body>
            <p>{{ $t("BRANDING.FORM_DESC") }}</p>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label for="brandname">{{ $t("BRANDING.COMPANY_NAME") }}</label>
              </b-col>
              <b-col lg="9" xl="4">
                <b-form-input
                  id="brandname"
                  v-model="$v.settings.brandname.$model"
                  :state="validateState('brandname')"
                  :placeholder="$t('BRANDING.COMPANY_NAME')"
                  :disabled="canAdd == false"
                  aria-describedby="input-brandname-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-brandname-feedback">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label for="brandlink">{{
                  $t("BRANDING.COMPANY_WEBLINK")
                }}</label>
              </b-col>
              <b-col lg="9" xl="5">
                <b-form-input
                  id="brandlink"
                  v-model="$v.settings.brandlink.$model"
                  :state="validateState('brandlink')"
                  :placeholder="$t('BRANDING.COMPANY_WEBLINK')"
                  :disabled="canAdd == false"
                  aria-describedby="input-brandlink-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-brandlink-feedback">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label for="supportemail">{{
                  $t("BRANDING.SUPPORT_EMAIL")
                }}</label>
              </b-col>
              <b-col lg="9" xl="4">
                <b-form-input
                  id="supportemail"
                  v-model="$v.settings.supportemail.$model"
                  :state="validateState('supportemail')"
                  :placeholder="$t('BRANDING.SUPPORT_EMAIL')"
                  :disabled="canAdd == false"
                  aria-describedby="input-supportemail-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-supportemail-feedback">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label>{{ $t("BRANDING.COMPANY_COLOR") }}</label>
              </b-col>
              <b-col lg="9" xl="4">
                <v-color-picker
                  v-model="$v.settings.brandcolor.$model"
                  mode="hexa"
                  dot-size="25"
                ></v-color-picker>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label>{{ $t("BRANDING.COMPANY_LOGO") }}</label>
              </b-col>
              <b-col lg="9" xl="4">
                <b-form-select
                  v-model="settings.selectInterface.selected"
                  value-field="value"
                  text-field="name"
                  :options="selectInterfaceData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="3" class="setting-form">
                <label for="basic_logo">
                  {{ $t("BRANDING.DEFAULT_LOGO_DESC") }} <br />
                  [GIF 210x35]
                </label>
              </b-col>
              <b-col lg="9" xl="5">
                <b-input-group>
                  <b-form-input
                    id="basic_logo"
                    v-model="$v.settings.basic_logo.$model"
                    :state="validateState('basic_logo')"
                    :placeholder="$t('BRANDING.DEFAULT_LOGO')"
                    :disabled="canAdd == false"
                    aria-describedby="input-basic_logo-feedback"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-success" @click="previewLogo">
                      {{ $t("BRANDING.PREVIEW") }}
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-basic_logo-feedback">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- ----------- preview logo modal -->
            <b-modal
              id="previewModal"
              :title="$t('BRANDING.PREVIEW_LOGO')"
              ref="previewModal"
              hide-footer
              :body-bg-variant="bodyBgVariant"
            >
              <b-img
                :src="settings.basic_logo"
                alt="Fluid-grow image"
                class="objectFit-none"
              ></b-img>
            </b-modal>
            <!-- ---------- preview logo modal end------------- -->
          </template>
          <template v-slot:foot>
            <b-button
              variant="primary"
              @click="updateSetting()"
              :disabled="canAdd == false"
            >
              {{ $t("BRANDING.UPDATE_SETTINGS") }}
            </b-button>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";

export default {
  mixins: [validationMixin],
  name: "branding",
  components: {
    KTPortlet,
  },
  data: () => ({
    selectDomain: {
      selectedDomain: "",
      allDomains: [],
    },
    settings: {
      brandname: "",
      brandlink: "",
      brandcolor: "",
      supportemail: "",
      selectInterface: {
        selected: "basic",
        // data: [{ name: "Basic Group-Office", value: "basic" }]
      },
      basic_logo: "",
    },
    bodyBgVariant: "info",
  }),
  computed: {
    selectInterfaceData: function () {
      return [{ name: "Basic Group-Office", value: "basic" }];
    },
    canAdd: function () {
      return this.hasPermission("branding", 5);
    },
  },
  created() {
    postRequest({
      action: "getAdminBrandInfo",
      token: localStorage.id_token,
    }).then((res) => {
      if (res.returncode) {
        this.settings.brandname = res.returndata.brandname;
        this.settings.brandlink = res.returndata.brandlink;
        this.settings.brandcolor = '#'+res.returndata.brandcolor;
        this.settings.supportemail = res.returndata.supportemail;
        this.settings.basic_logo = res.returndata.basic_logo;
      } else {
        this.makeToastVariant("danger", res.returndata);
      }
    });
  },
  validations: {
    settings: {
      brandname: {
        required,
      },
      brandlink: {
        required,
      },
      brandcolor: {
      },
      supportemail: {
        required,
        email,
      },
      basic_logo: {
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.BRANDING") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.settings[name];
      return $dirty ? !$error : null;
    },

    /* ---------- preview modal ---------- */
    previewLogo() {
      this.$refs["previewModal"].show();
    },

    /* ---------- update setting ---------- */
    updateSetting() {
      this.$v.settings.$touch();
      if (this.$v.settings.$anyError) {
        return;
      }
      postRequest({
        action: "updateBranding",
        token: localStorage.id_token,
        brandname: this.settings.brandname,
        brandlink: this.settings.brandlink,
        brandcolor: this.settings.brandcolor.substr(1, 6),
        supportemail: this.settings.supportemail,
        selectInterface: this.settings.selectInterface.selected,
        basic_logo: this.settings.basic_logo,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
